import React from 'react';
import {
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
    TwitterShareButton,
    XIcon
} from 'react-share'

export default function Share(props) {
    
    return (
    <React.Fragment>
        <WhatsappShareButton
            url={props.url}
            title={props.title}
        >
            <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <TelegramShareButton url={props.url} title={props.tilte}>
            <TelegramIcon size="32" round/>
        </TelegramShareButton>
        <TwitterShareButton url={props.url} title={props.title} related={['@ModNHQ']}>
            <XIcon size="32" round/>
        </TwitterShareButton>
        <EmailShareButton url={props.url}>
            <EmailIcon size="32" round/>
        </EmailShareButton>
    </React.Fragment>
    );
}
