import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import "firebase/database";
import {FirebaseDatabaseProvider} from '@react-firebase/database';
import ReactGA from 'react-ga';
ReactGA.initialize('G-8V893QB4G7');
ReactGA.pageview(window.location.pathname + window.location.search);

const firebaseConfig = {
  apiKey: "AIzaSyBFuHsNAM03R9aCG612LGRyoPWiLTV2hRU",
  authDomain: "eid-gift.firebaseapp.com",
  databaseURL: "https://eid-gift-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "eid-gift",
  storageBucket: "eid-gift.appspot.com",
  messagingSenderId: "1053619001384",
  appId: "1:1053619001384:web:a37f77cd018208cc19fd1d",
  measurementId: "G-WPJSRBTWVN"
};

const theme = createMuiTheme({
  direction: 'rtl',
  typography: {
    fontFamily: [
      'bahij',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  },
  palette: {
    primary: {
      main: '#e25555',
    }
  }
});


// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });






ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <StylesProvider jss={jss}>
      <div dir="rtl">
      <FirebaseDatabaseProvider {...firebaseConfig} firebase={firebase}>
      <App />
      </FirebaseDatabaseProvider>
      </div>
      </StylesProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
