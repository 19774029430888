import React from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import MatchingContent from './MatchingContent';
import { FirebaseDatabaseNode } from '@react-firebase/database'


const useStyles = makeStyles((theme) => ({
    paper: {
    padding: theme.spacing(0),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
        padding: theme.spacing(0),
    },
    textAlign: 'center',
    backgroundColor:'transparent'
    },
    fname: {
        margin: theme.spacing(4)
    },
    fage: {
        margin: theme.spacing(2)
    },
    title:{
        marginTop: theme.spacing(10)
    }
}));

export default function Matching() {

    const classes = useStyles();


    let { id } = useParams();

    
    return (
        <React.Fragment>
            <Paper className={classes.paper} elevation={0}>
                <CardContent>
                    <FirebaseDatabaseNode path={"eidyah/"+id}>
                        {data => {
                            if (data === null || typeof data === "undefined"){
                                return false;
                            }
                            const { value } = data;
                            if (value === null) {
                                return false;
                            }
                            let keys = Object.keys(value)
                            let dataObject = value[keys[0]];
                            delete dataObject.inputs.placeholder
                            return <MatchingContent name={dataObject.name} eidyah={Object.values(dataObject.inputs)}/>
                        }}
                    </FirebaseDatabaseNode>
                </CardContent>
            </Paper>
        </React.Fragment>
    );
}
