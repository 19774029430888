import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Welcome() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom align="center">
        لأن العيد فرحة، وتهادوا تحابوا، بين يديكم عيديّة:
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom align="center">
        أدخل عيديّاتك ثم شارك الرابط مع من تحب، وسيظهر لكلٍ منهم -عشوائيًا- عيديّته!
      </Typography>
    </React.Fragment>
  );
}
