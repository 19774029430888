import React from 'react';
import {Link as LinkReact } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import RandomRoller from 'react-random-roller';
import { makeStyles } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import Share from './Share';


const useStyles = makeStyles((theme) => ({
    paper: {
    padding: theme.spacing(0),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
        padding: theme.spacing(0),
    },
    textAlign: 'center',
    backgroundColor:'transparent'
    },
    fname: {
        margin: theme.spacing(4)
    },
    fage: {
        margin: theme.spacing(2)
    },
    title:{
        marginTop: theme.spacing(10)
    },
    customLink:{
        color:'inherit',
        '&:hover':{
            textDecoration:'none'
        }
    },
    pic:{
        marginBottom: theme.spacing(4)
    }
}));

export default function MatchingContent(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <AddAPhotoIcon fontSize='large' className={classes.pic}/>
            <Typography  variant="h5" component="h5">
                صوّر الشاشة، وأرسلها لـ {' '}
                <Typography color="primary"  variant="h4" component="span">
                    {props.name}
                </Typography>، أو انتظر إلى أن تتوقف!
            </Typography>
            <Typography className={classes.title} variant="body1" gutterBottom>
                عيديّتي هي:
            </Typography>
            <Typography color="primary" variant="h2" className={classes.fname} component="h2">
                <RandomRoller auto list={props.eidyah} message="اضغط للاختيار" duration={250*60} fps={25} />
            </Typography>
            <Typography  variant="subtitle1" component="h2">
                مبارك عليك العيديّة، ونحن في <span dir="ltr">≡ Mod(N)</span> نتمنى لك عيدًا سعيدًا!
            </Typography>
            <Typography  variant="subtitle1" component="h2" style={{marginTop:'2.5rem'}}>
                شارك الآخرين الفرحة:
                <br/>
                <Share url={window.location.origin} title={'عرفت عيديّتي من '+props.name+' باستخدام '+'"عيديّة" من ≡ Mod(N)'+''}/>
                <br/><br/>
                <Typography color="primary">
                    <LinkReact to="/" className={classes.customLink}>
                            أنشئ رابط لعيديّاتك، وانشر البهجة!
                    </LinkReact>
                </Typography>
                
            </Typography>
            
        </React.Fragment>

    )

}