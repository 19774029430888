import React from 'react';
import {Link as LinkReact } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Layout from './Layout'
import './App.css';
import {HashRouter as Router,Switch,Route} from "react-router-dom";
import { Box } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="subtitle2" color="textSecondary" align="center">
      {'جميع الحقوق محفوظة © '}
      <Link color="inherit" dir="ltr" href="https://modn.xyz/">
      ≡ Mod(N)
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  head: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  partOf:{
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    fontSize:'0.875rem'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
  },
  customLink:{
    color:'inherit',
    textDecoration:'none',
    '&:hover':{
      textDecoration:'none'
    }
  }
}));



export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
      <Typography component="h1" className={classes.head}  variant="h2" align="center">
        <LinkReact to='/' className={classes.customLink}>
        
          عيديّة
        </LinkReact>
      
      <Typography component="h6" className={classes.partOf}>
      * أحد مشاريع {' '}

      <Link  href="https://modn.xyz" dir="ltr" color="primary">
        ≡ Mod(N)
        </Link>
        </Typography>
        
        
        
      </Typography>
      
          <Switch>
            <Route path="/eidyah/:id">
              <Layout page="matching" card/>
            </Route>
            <Route exact path="/">
              <Layout page="maker"/>
            </Route>
          </Switch>
        </Router>
      <footer className={classes.footer}>
        <Container maxWidth="md">
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}
