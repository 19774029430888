import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Welcome from './Welcome';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CopyToClipboard from "./CopyToClipboard";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Box from '@material-ui/core/Box'
import Share from './Share'
import { FirebaseDatabaseMutation} from '@react-firebase/database';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { Divider } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
    backgroundColor:'transparent'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  
  mt2: {
    marginTop: theme.spacing(2),
  }
}));



const steps = ['خطوة أولى', 'أدخل العيديات','شارك الرابط مع من تحب'];

var eidyahVal = 0;

var isValid = false;

function makeid(length) {
  var result           = [];
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result.push(characters.charAt(Math.floor(Math.random() * 
    charactersLength)));
  }
  return result.join('');
}


let id = makeid(6)

export default function Maker() {


  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [pubKey,setPubkey] = React.useState(false);
  const [eidyah,setEidyah] = React.useState({"placeholder":true})
  const [members, setMember] = React.useState(['memeber-1','memeber-2']);
  const [isMax, setIsMax] = React.useState(false);
  const [validVals, setValidVals] = React.useState({0:false,1:false,2:false});
  
  
  
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  
  const handleEidyah = (e) => {
    validate(e)
    setEidyah((prev)=>{
      let eidyah = Object.assign({}, prev);
      eidyah[e.target.id] = e.target.value;
      eidyahVal = eidyah
      return eidyah
    },)
    
  }

  const validate = (e) => {
    let val = e.target.value;
    let id = e.target.id;
    if(val === ""){
      console.error(id,' - Not valid: ',val)
      setValidVals((prev)=>{
        prev[id] = false;
        return prev
      })
    }else{
      setValidVals((prev)=>{
        prev[id] = true
        return prev
      })  
    }
    isValid = Object.values(validVals).every((v)=>v===true)
    return isValid
  }
  
  const addMemberInput = (e) => {
    
    if((members.length) >= 20){
      console.error('Maximum reached!')
      setIsMax(true)
      return;
    }
    var newInput = `memeber-${members.length +1}`;
    setMember(members.concat([newInput]))
  }
  
  
  return (
    <React.Fragment>
      <Stepper activeStep={activeStep} className={classes.stepper}>
      {steps.map((label) => (
        <Step key={label}>
        <StepLabel>{label}</StepLabel>
        </Step>
        ))}
        </Stepper>
        <React.Fragment>
          {activeStep === (steps.length-1) ? (
          <React.Fragment>
            <Typography variant="h4" gutterBottom>
              رائع!
            </Typography>
            <Typography variant="subtitle1">
              قُم بمشاركة الرابط أدناه مع من تُحب ليعرف عيديّته:
            </Typography>
            <Grid container className={classes.mt2} spacing={1}>
              <Grid item sm={10} xs={9} lg={11} md={11}>
                <TextField dir="ltr" label="رابط العائلة" id="url" defaultValue={window.location.origin+'/#/eidyah/'+pubKey} fullWidth autoComplete="cc-name" />
              </Grid>
              <Grid item  sm={2} xs={3} lg={1} md={1}>
                <CopyToClipboard>
                {({ copy }) => (
                  <Button fullWidth color="primary" variant="contained" onClick={() => copy(document.getElementById('url').value)} className={classes.button}>
                  <FileCopyIcon/>
                  </Button>
                )}
                </CopyToClipboard>
              </Grid>
            </Grid>
            <Box>
              <Share url={window.location.origin+'/#/eidyah/'+pubKey} title={'عِيدك مُبارك، وتفضّل اعرف عيديّتي لك: '} />
            </Box>
          </React.Fragment>
          ) : (
          <React.Fragment>
            {
              activeStep === 1 && (
                <React.Fragment>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} >
                      <TextField onChange={validate} required id="0"  label="اسمك الكريم، ليظهر في رابط العيديّة" fullWidth autoComplete="cc-name" />
                    </Grid>
                    <Divider variant="fullwidth" style={{width:'80%',margin:'2rem auto'}} orientation="horizontal"/>
                    {members.map((input, index) => (
                      <React.Fragment>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField  required={(index+1) > 2 ? false : true} id={index+1} index={index+1} onChange={handleEidyah}  label={"العيديّة #"+(index+1)} fullWidth autoComplete="cc-name" />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                  <FormControl fullWidth margin="dense">
                    <Tooltip title="أضف المزيد">
                      <Fab
                        color="primary"
                        size="small"
                        onClick={addMemberInput}
                        disabled={isMax ? true : false}
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </FormControl>
                </React.Fragment>
                  )
            }
            {
              activeStep === 0 ? (
                <Welcome />
                ): ('')
            }
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} className={classes.button}>
                  <NavigateNextIcon/> 
                </Button>
              )}
              {activeStep === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === (steps.length - 2) ?  "أنشئ الرابط": <ArrowBackIosIcon />}
                </Button>
              )}


              {activeStep === 1 && (
                <FirebaseDatabaseMutation  type="push" path={'/eidyah/'+id}>
                  {({ runMutation }) => {
                    
                    return (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          if(isValid !== true){
                            console.error('Invalid inputs.')
                            return
                          }
                          const data = {
                            name: document.getElementById('0').value,
                            inputs: eidyahVal
                          }
                          await runMutation(data);
                          setPubkey(id)
                          handleNext()
                        }}
                        className={classes.button}
                        >
                        أنشئ الرابط
                      </Button>
                    )
                  }}
                </FirebaseDatabaseMutation>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}